import { GenericFormType, useTranslations, RegistrationType } from "@eco/react-lib"

export const useContactFormSchema = (defaultValue?: RegistrationType & { country: string}): GenericFormType => {

    const [
        VALIDATE,
        EMAIL_LABEL,
        LASTNAME_LABEL,
        FIRSTNAME_LABEL,
        COMPANY_LABEL,
        EMAIL_ERROR,
        PHONE,
        COUNTRY,
        ZONE,
        UK,
        EUROPE,
        NORTHAMERICA,
        SOUTHAMERICA,
        ASIA,
        AFRICA,
        OCEANIA,
        MESSAGE,
        SUBJECT,
        OTHER,
        COMMERCIAL,
        TECHNICAL,

    ] = useTranslations(i18n)
    return {
        schema: {
            btnSubmit: {
                text: VALIDATE,
                className: 'btn-primary w-25'
            },
            type: 'object',
            required: ["organization", "firstName", "lastName", "email", "phone", "country", "message", 'subject'],
            properties: {
                subject: {
                    type: "string",
                    title: SUBJECT,
                    anyOf: [
                        {
                            type: "string",
                            title: TECHNICAL,
                            enum: ["[TECHNICAL]"]
                        },
                        {
                            type: "string",
                            title: COMMERCIAL,
                            enum: ["[SALES]"]
                        },
                        {
                            type: "string",
                            title: OTHER,
                            enum: ["[OTHER]"]
                        },
                    ]
                },
                email: {
                    type: "string",
                    title: EMAIL_LABEL,
                    pattern: '^\\s*[a-z0-9._%+-]+@[a-z0-9\\.-]+\\.[a-z]{2,4}\\s*$',
                    default: `${defaultValue?.email ? defaultValue?.email : ''}`,
                },
                lastName: {
                    type: "string",
                    title: LASTNAME_LABEL,
                    default: `${defaultValue?.lastName ? defaultValue?.lastName : ''}`
                },
                firstName: {
                    type: "string",
                    title: FIRSTNAME_LABEL,
                    default: `${defaultValue?.firstName ? defaultValue?.firstName : ''}`
                },
                organization: {
                    type: "string",
                    title: COMPANY_LABEL,
                    default: `${defaultValue?.organization ? defaultValue?.organization : ''}`
                },
                phone: {
                    type: "string",
                    title: PHONE,
                    default: ''
                },
                country: {
                    type: "string",
                    title: COUNTRY,
                    default: `${defaultValue?.country ? defaultValue?.country : ''}`,
                },
                zone: {
                    type: "array",
                    title: ZONE,
                    items: {
                        type: "string",
                        anyOf: [
                            {
                                type: 'string',
                                title: EUROPE,
                                enum: [ 'Europa' ]
                            },
                            {
                                type: 'string',
                                title: UK,
                                enum: [ 'United Kingdom' ]
                            },
                            {
                                type: 'string',
                                title: NORTHAMERICA,
                                enum: [ 'North America' ]
                            },
                            {
                                type: 'string',
                                title: SOUTHAMERICA,
                                enum: [ 'South America' ]
                            },
                            {
                                type: 'string',
                                title: ASIA,
                                enum: [ 'Asia' ]
                            },
                            {
                                type: 'string',
                                title: AFRICA,
                                enum: [ 'Africa' ]
                            },
                            {
                                type: 'string',
                                title: OCEANIA,
                                enum: [ 'Oceania' ]
                            },
                        ],
                    },
                    uniqueItems: true,
                    default: []
                },

                message: {
                    type: "string",
                    title: MESSAGE,
                },

            },
        },
        uiSchema: {
            zone: {
                "ui:widget": "checkboxes",
            },
            message: {
                'ui:widget': 'textarea'
            },
            country: {
                "ui:field": "SelectCountry",
                "ui:options": { "isNativeName": true },
            }
        },
        transformErrors: (errors) => (
            errors.map((e) => {
                if (e.name === 'pattern' && e.property === '.email') {
                    e.message = EMAIL_ERROR
                }
                return e
            })
        ),
    }
}

export type MessageInfo = {
    email: string;
    lastName: string;
    firstName: string;
    organization: string;
    phone: string;
    country: string;
    zone: string[];
    message: string;
    subject: string;
    regulatoryZone?: string;
    mailing?: Array<boolean>;
}


const i18n = [
    '1Click.ContactForm.Save.button',
    '1Click.ContactForm.EmailInput.label',
    '1Click.ContactForm.LastName.label',
    '1Click.ContactForm.FirstName.label',
    '1Click.ContactForm.Company.label',
    '1Click.Common.Form.Invalid.error',
    '1Click.ContactForm.Phone.label',
    '1Click.ContactForm.Country.label',
    '1Click.ContactForm.Zone.label',
    '1Click.ContactForm.Zone.UK',
    '1Click.ContactForm.Zone.Europe',
    '1Click.ContactForm.Zone.NorthAmerica',
    '1Click.ContactForm.Zone.SouthAmerica',
    '1Click.ContactForm.Zone.Asia',
    '1Click.ContactForm.Zone.Africa',
    '1Click.ContactForm.Zone.Oceania',
    '1Click.ContactForm.MessageTextarea.label',
    '1Click.ContactForm.Subject.label',
    '1Click.ContactForm.Subject.Other.label',
    '1Click.ContactForm.Subject.Commercial.label',
    '1Click.ContactForm.Subject.Technical.label',
]
