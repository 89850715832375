import {QuizResult, customNodeType, useTranslations, QuizTitle} from '@eco/react-lib';

export const CustomNodeMDC6 = ({metaData}: customNodeType) => {
    const [TITLE] = useTranslations(i18n(metaData?.nodeName))
    return (
        <>
            <QuizTitle title={`${TITLE}`}/>
            <QuizResult img="/assets/img/b-17.png"/>
        </>
    )
}

export default CustomNodeMDC6;

const i18n = (key?: string) => [
    `MedicalDevice.${key}.text`
]