import {EcoRoot, StaticRouteMappings, staticRouteMappingsState, EcoInitialState } from "@eco/react-lib" ;
import Layout from 'shares/components/layout/Layout';
import Quiz from './pages/quiz/Quiz'
import QuizMain from './pages/quiz/QuizMain'
import Contact from './pages/contact/Contact'
import './shares/styles/md.css'
import './app.css';
import '@eco/react-lib/dist/index.css';
import Registration from "./pages/registration/Registration";
import Home from "pages/home/Home";

function App():  JSX.Element {
    // Update from another post 2 Branch 3
    // custom state
    const initialState = initialAppState() ;
    
    return (
        <EcoRoot initialState={initialState} defaultRoute="/home" />
    );
}



/**
 * Build initial recoil app state.
 * 
 * @returns initial application state
 */
const initialAppState = () : EcoInitialState[] => {

    // static routes
    const staticRoutes: StaticRouteMappings = {
        Layout: <Layout />,
        Quiz: <Quiz />,
        Home: <Home />,
        QuizMain: <QuizMain />,
        Registration: <Registration/>,
        Contact: <Contact />
    }
    
    // No translations from backend > i18n defined in public/i18n 
    // const translations = new TranslationMap([]) ;
    // translations.setLoaded() ; 
    
    return [
        {
            state : staticRouteMappingsState,
            value : staticRoutes
        }
    ] ;

}


export default App;
