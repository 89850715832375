import { useEcoClient, useEcoMutation } from "@eco/react-lib"
import { PostMessage } from "./useContactFormHandler"

export const useSendMessageMutation = () => {

    const ecoClient = useEcoClient()

    return useEcoMutation((data: PostMessage) => ecoClient.post({
        path: '/quiz.contact',
        data,
        dataType: 'json'
    }))
}