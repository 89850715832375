import {useEffect, useState} from 'react';
import { useNavigate } from "react-router"
import {quizLoaded, useQuizStore} from "@eco/react-lib"
import { useSetRecoilState } from 'recoil';

export const useSelectQuiz = () => {
    const setQuizLoaded = useSetRecoilState(quizLoaded)
    const { removeStore, getQuizInfo } = useQuizStore()
    const naviagte = useNavigate()
    const [selectedQuiz, setSelectedQuiz] = useState<string>()

    useEffect(() => {
        if(selectedQuiz) {
            if( getQuizInfo()?.name != selectedQuiz || getQuizInfo()?.isFormInstance) {
                setQuizLoaded({})
                removeStore()
                console.log('ok')
            }
            naviagte(`/quiz/${selectedQuiz}`)
        }
    }, [selectedQuiz])

    return {
        selectedQuiz,
        setSelectedQuiz
    }
}