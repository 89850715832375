import { useTranslations, GenericFormTitle, GenericForm, GenericDialog, useSaveResponeQuiz, useGAEvent } from "@eco/react-lib";
import { Button } from "@mui/material";
import { useEffect, ReactElement } from "react";
import { useNavigate } from "react-router";
import { useContactFormHandler } from "./hooks/useContactFormHandler";
import { MessageInfo, useContactFormSchema } from "./hooks/useContactFormSchema";

const Contact = (): ReactElement => {

    const [
        TITLE,
        POPUP_TITLE,
        POPUP_MESSAGE,
        BACK
    ] = useTranslations(i18n);
    const { defaultUser: userInfo, isLoading: gettingUserInfo } = useSaveResponeQuiz();

    const { handleFormSubmit, popUpOpened, closePopUp, initUserInfo, isLoading } = useContactFormHandler();
    
    const profileFormSchema = useContactFormSchema();
    const navigate = useNavigate();
    useGAEvent({
        category: 'Page',
        action: 'Contact',
        label: 'Quiz/UserGotoContactUs'
    })

    useEffect(() => {
        if  (!userInfo) return;
        initUserInfo(userInfo as MessageInfo);
    }, [userInfo])
    
    return (
        <div className="mdContainer-s">
            <Button onClick={() => navigate(-1)} className="backButton">{BACK}</Button>
            <GenericFormTitle
                subTitle={TITLE}
            />
            {
                !gettingUserInfo && 
                
                <GenericForm
                    {...profileFormSchema}
                    defaultValue={userInfo}
                    onSubmit={(value?: Record<string, any>) => handleFormSubmit(value as MessageInfo)}
                    isSubmiting={isLoading}
                />
            }
            <GenericDialog
                open={popUpOpened}
                dialog= {
                    {
                        text: `${POPUP_TITLE} \n \n ${POPUP_MESSAGE} \n \n`,
                        // img: '/assets/img/2Ableu-mini.png',
                        content:<>
                        </>
                    }
                }
                onClose={closePopUp}
                fullWidth={true}
            />
        </div>
    )
}

const i18n = [
    '1Click.ContactForm.title',
    '1Click.ContactForm.Popup.title',
    '1Click.ContactForm.Popup.message',
    'MedicalDevice.Back.button'
]

export default Contact
