import {customNodeType} from '@eco/react-lib'
import CheckStep from 'shares/components/checkResponse/CheckStep';

const CustomNodeMDC51 = ({metaData, onRespond}: customNodeType): JSX.Element => {
    return(
        <CheckStep metaData={metaData} onRespond={onRespond} />
    )
}

export default  CustomNodeMDC51



