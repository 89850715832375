import { Link } from "react-router-dom";
import {
    customNodeType,
    QuizResult,
    QuizTitle,
    useTranslations,
} from "@eco/react-lib";
import { InfoOutlined } from "@mui/icons-material";

const CustomNodeMD17 = ({ metaData }: customNodeType): JSX.Element => {
    const [TITLE, BUTTON, INFO] = useTranslations(i18n(metaData?.nodeName));

    return (
        <>
            <QuizTitle title={`${TITLE}`} />
            <QuizResult img="/assets/img/b-12.png" />
            <div>
                <Link to="/quiz" className="mdBtn upperCase">
                    {BUTTON}
                </Link>
                <p className="mdInfo">
                    <InfoOutlined color="warning" fontSize="small" />
                    {INFO}
                </p>
            </div>
        </>
    );
};

export default CustomNodeMD17;

const i18n = (key?: string) => [
    `MedicalDevice.${key}.text`,
    `MedicalDevice.${key}.button`,
    `MedicalDevice.${key}.info`,
];
