import {customNodeType, QuizTitle, useQuizStore, useTranslations} from '@eco/react-lib'
import CheckResponse from 'shares/components/checkResponse/CheckResponse';

const CheckStep = ({metaData, onRespond}: customNodeType): JSX.Element => {

    const [TITLE, DESC, SUGEST, QUESTION, BUTTON] = useTranslations(i18n)
    const {getQuizInfo, getStepsList, getResponsesList} = useQuizStore()

    return(
        <div className="checkResponse">
            <QuizTitle title={TITLE}/>
            <div className="stext-center">
                <img className="eco-img" src="/assets/img/b-15.png" alt='img' />
                <p>{DESC}</p>
                <p>{SUGEST}</p>
            </div>
            <ul className="checkRespone-list">
                {
                    getStepsList()?.map((step: string, key: number) => {
                        if(key < getStepsList()?.length - 1) {
                            return (
                                <li key={key}>
                                    <CheckResponse
                                        question={step}
                                        response={getResponsesList()?.[key]}
                                        index={key}  
                                        link={`/quiz/${getQuizInfo()?.name}/${step}`} />
                                </li>
                            )
                        }
                    })
                }
            </ul>
            <div className="stext-center">
                <p>{QUESTION}</p>
                <button className="mdBtn" onClick={() => {onRespond?.((Object.keys(metaData.on)[0])) }}>{BUTTON}</button>
            </div>
        </div>
    )
}

export default  CheckStep

const i18n = [
    `MedicalDevice.CheckResponses.Title.txt`,
    `MedicalDevice.CheckResponses.Description.txt`,
    'MedicalDevice.CheckResponses.Suggestion.txt',
    'MedicalDevice.CheckResponses.Question.txt',
    'MedicalDevice.CheckResponses.Yes.button'
]

