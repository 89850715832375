import { useTranslations } from '@eco/react-lib'
import { Link } from 'react-router-dom'

const CheckResponse = ({question, response, index, link}: CheckResponseType): JSX.Element => {
    const [QUESTION, RESPONSE] = useTranslations(i18n(question, response))
    return(
        <>
            <Link to={link}> 
                <strong>{`Question: `} </strong>
                {`${QUESTION}`}
            </Link>
            <p>{`> `} {RESPONSE} </p>
        </>
    )
}

export default  CheckResponse

const i18n = (question: string, response: string) => [
    `MedicalDevice.${question}.text`,
    `MedicalDevice.${response}.text`
]

type CheckResponseType = {
    question: string 
    response: string
    index: number
    link: string
}