import { useQuizStore, useTranslations } from '@eco/react-lib'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'

export const Home: React.FC = () => {
    const { getQuizInfo, getlastStep } = useQuizStore()
    const navigate = useNavigate()

    useEffect(() => {
        if (getQuizInfo()?.name && getlastStep()) {
            navigate(`/quiz/${getQuizInfo()?.name}/${getlastStep()}`)
        }
        else {
            navigate('/quiz')
        }
    }, [])

    const [BUTTON] = useTranslations(i18n)
    return (
        <div className='mdContainer-s'>
            <Link to='/quiz' className='mdBtn'>
                {BUTTON}
            </Link>
        </div>
    )
}

export default Home

const i18n = [
    'MedicalDevice.Home.GotoQUiz.button'
]
