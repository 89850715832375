import { useDisplayAlert, useTranslations } from "@eco/react-lib";
import { ISubmitEvent } from "@rjsf/core";
import { useEffect, useState } from "react";

import { MessageInfo } from "./useContactFormSchema";
import { useSendMessageMutation } from "./useSendMessageMutation";
import ReactGA from "react-ga";

export const useContactFormHandler = (): Result => {
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ userInfo , setUserInfo ] = useState<MessageInfo>();
    const { mutate, isSuccess, isError } = useSendMessageMutation();
    const displayAlert = useDisplayAlert();
    const [
        ERROR,
    ] = useTranslations(i18n)

    useEffect(() => {
        setIsLoading(false);
        if (!isSuccess) return;
        setSuccess(true);
    }, [isSuccess])

    useEffect(() => {
        if (!isError) return;
        displayAlert({
            id: `send-message-failed`,
            label: ERROR,
            autoHideDuration: 15000,
            AlertProps: {
                severity: 'error',
            },
        })
        
    }, [isError])

    const handleFormSubmit = (value: MessageInfo) => {
        setIsLoading(true);
        const formData = userInfo || value
        const {
            email: originEmail,
            lastName: originLastName,
            firstName: originFirstName,
            organization: originOrganization,
            country: originCountry,
        } = formData;
        const data: PostMessage = {
            ...formData,
            entrId: undefined,
            mailing: undefined,
            originEmail,
            originLastName,
            originFirstName,
            originOrganization,
            originCountry,
            regulatoryZone: formData.zone ? (formData.zone as Array<string>).join('-') : undefined,
        }
        mutate(data)
        ga_SubmitContactUs()


    }

    return {
        handleFormSubmit,
        isLoading,
        popUpOpened: success,
        closePopUp: () => setSuccess(false),
        initUserInfo:  (info: MessageInfo) => setUserInfo(info)
    }
}
type Result = {
    handleFormSubmit: (value: MessageInfo) => void,
    isLoading: boolean,
    popUpOpened: boolean,
    closePopUp: () => void,
    initUserInfo: (info: MessageInfo) => void,
}

export type PostMessage = MessageInfo & {
    originEmail: string;
    originLastName: string;
    originFirstName: string;
    originOrganization: string;
    originCountry: string;
    entrId?: number
}



const i18n = [
    '1Click.ContactForm.Save.error',
]

const ga_SubmitContactUs = () => {
    ReactGA.event({
      category: 'Button',
      action: 'SubmitContactUs',
      label: 'Quiz/ContactUs/UserSubmitContactUs',
    });
}