import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { QuizForm, isUserRegistered, isQuizSaved, quizLoaded, useQuizStore, EcoSpinner, useDebounce, useLocalStorage, useTranslations } from "@eco/react-lib"
import components from '../../shares/components/customNode/CustomNodeMain'
import { useSetRecoilState } from 'recoil';
import ReactGA from 'react-ga';


export const QUIZ: React.FC = (): JSX.Element => {
    const { pathname } = useLocation()
    const splitedPath = pathname.split('/')
    const name = splitedPath[2]
    const urlParams = new URLSearchParams(useLocation().search);
    const setIsRegistered = useSetRecoilState(isUserRegistered)
    const setIsSaved = useSetRecoilState(isQuizSaved)
    const setQuizLoaded = useSetRecoilState(quizLoaded)
    const { removeStore } = useQuizStore()
    const [isLoading, setIsLoading] = useState(true)
    const step = splitedPath[3]
    const [KEY] = useTranslations(i18n(step))

    useEffect(() => {
        if(urlParams.get("instanceId") && urlParams.get("userId")) {
            ga_GotoResponseViaMail(urlParams.get("userId") as string, urlParams.get("instanceId") as string)
        } else if (step) {
            ga_GotoStep(KEY)
        }
    }, [pathname, urlParams])

    useEffect(() => {
        if(urlParams.get("instanceId") && urlParams.get("userId") ) {
            setQuizLoaded({});
            removeStore();
            setIsRegistered(true);
            setIsSaved(true);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }, [urlParams])
    return (
        <>
            {
                isLoading ? <div className="quizSpinner"><EcoSpinner style={{ fontSize: 128 }} /></div>
                : <QuizForm 
                    userId={urlParams.get("userId") || undefined} 
                    id={urlParams.get("instanceId") ||  undefined} 
                    customNodes={components} 
                    route={`/${splitedPath[1]}/${splitedPath[2]}`} 
                    quizName={name} 
                    registrationUrl={`/registration`}/>
                
            }
        </>
    )
}

export default QUIZ;


const ga_GotoStep= (text: string) => {
    ReactGA.event({
      category: 'Step',
      action: 'GotoStep',
      label: `Quiz/Step/${text}`,
    });
}

const ga_GotoResponseViaMail = (userId: string, instanceId: string) => {
    ReactGA.event({
      category: 'Link',
      action: 'ClickLinkToQuizResutlOnMail',
      label: `Quiz/GotoResponseViaMail?userId=${userId}&instanceId=${instanceId}`,
    });
}

const i18n = (key: string) => [
    `MedicalDevice.${key}.text`
]