import {customNodeType, QuizResult, QuizTitle, useTranslations} from '@eco/react-lib'

const CustomNodeMD8 = ({metaData}: customNodeType): JSX.Element => {
    const [TITLE] = useTranslations(i18n(metaData?.nodeName))

    return(
        <>
            <QuizTitle title={`${TITLE}`}/>
            <QuizResult img="/assets/img/b-19.png"/>
        </>
    )
}

export default  CustomNodeMD8

const i18n = (key?: string) => [
    `MedicalDevice.${key}.text`
]