import { GenericForm, quizLoaded, QuizProgress, QuizTitle, useTranslations, isUserRegistered, useRegistrationFormSchema, useSaveResponeQuiz, ButtonSubmit, useGAEvent } from "@eco/react-lib"
import { Button } from "@mui/material"
import { useNavigate } from "react-router"

import { useRecoilValue } from "recoil"

const Registration = () => {
    const registrationSchema = useRegistrationFormSchema()
    const navigate = useNavigate()
    const {defaultUser, handleSubmit, handleChange, handleShowResult, isLoading} = useSaveResponeQuiz()
    const [BACK, TITLE, SUBTITLE] = useTranslations(i18n)
    const quizLoad = useRecoilValue(quizLoaded)
    const isRegistered = useRecoilValue(isUserRegistered)
    useGAEvent({
        category: 'Page',
        action: 'Register',
        label: 'Quiz/UserRegister'});

    return(
    <div className="quizForm">
        <div className="quizForm-sideBar">
                <QuizProgress total={quizLoad.depth || 0} stepLeft={0}/>
            </div>
        <div className="quizForm-body">
            <div className='quizTopHeader'>
                <Button onClick={() => navigate(-1)} className="backButton">{BACK}</Button>
            </div>
            <QuizTitle title={TITLE} subTitle={isRegistered ? '' : SUBTITLE} />
            <div className="mdregistration">
                {
                    isRegistered ? 
                    <>
                        <img src="/assets/img/b-14.png" alt="src" />
                        <ButtonSubmit isSubmit={isLoading} onClick={() => handleShowResult()} label={`Voir mon resultat`}/>
                    </> 
                   
                    :
                    <GenericForm 
                        {...registrationSchema} 
                        defaultValue={defaultUser}
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                        imgSrc="/assets/img/b-14.png"
                        isDirtyUx={true}
                        isSubmiting={isLoading}
                    />
                }
            </div>
        </div>
    </div>
    )
}

export default Registration

const i18n = [
    `MedicalDevice.Back.button`,
    `MedicalDevice.CheckFinished.Title.txt`,
    `MedicalDevice.CheckFinished.SubTitle.txt`

]