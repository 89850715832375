import CustomNodeMD8 from './components/CustomNodeMD8';
import CustomNodeMD9 from './components/CustomNodeMD9';
import CustomNodeMD17 from './components/CustomNodeMD17';
import CustomNodeMDC51 from './components/CustomNodeMDC51';
import CustomNodeMDC5 from './components/CustomNodeMDC5';
import CustomNodeMDC6 from './components/CustomNodeMDC6';
import CustomNodeMDC7 from './components/CustomNodeMDC7';
import CustomNodeMDC12 from './components/CustomNodeMDC12';
import CustomNodeMDC88 from './components/CustomNodeMDC88';

const components: any = {};

// MD
components['CustomNodeMD8'] = CustomNodeMD8;
components['CustomNodeMD9'] = CustomNodeMD9;
components['CustomNodeMD17'] = CustomNodeMD17;


// MDC
components['CustomNodeMDC88'] = CustomNodeMDC88;
components['CustomNodeMDC51'] = CustomNodeMDC51;
components['CustomNodeMDC5'] = CustomNodeMDC5;
components['CustomNodeMDC6'] = CustomNodeMDC6;
components['CustomNodeMDC7'] = CustomNodeMDC7;
components['CustomNodeMDC12'] = CustomNodeMDC12;

export default components