import React from 'react';
import {QuizChoice, QuizTitle, useTranslations, useQuizStore} from "@eco/react-lib"
import { useSelectQuiz } from 'shares/hooks/useSelectQuiz';
import ReactGA from 'react-ga';
import { makeStyles } from '@mui/styles';

export const QUIZ: React.FC = () => {
    const [QUESTION, MD, MDC, DESC, TITLE1, TITLE2] = useTranslations(i18n)
    const {setSelectedQuiz} = useSelectQuiz()
    const {removeStore}= useQuizStore()
    const classes = useStyle()

    return (
        <div className="quizMain">
            <div className={classes.description}>
                <p className='mdTxt'>
                    {TITLE1} <strong>{TITLE2}</strong>, {DESC}
                </p>
            </div>
            <QuizTitle title={QUESTION}/>
            <ul className="quizResponse">
                <li onClick={() => {setSelectedQuiz('medical-device'); removeStore(); ga_ChooseMD()}}>
                    <QuizChoice txt={MD} imgId={'picto-MD'} />
                </li>
                <li onClick={() => {setSelectedQuiz('medical-device-classif'); removeStore(); ga_ChooseMDC()}}>
                    <QuizChoice txt={MDC} imgId={'picto-MDC'} />
                </li>
            </ul>
        </div>
    )
}

export default QUIZ;

const i18n = [
    `MedicalDevice.QuizMain.Question.txt`,
    `MedicalDevice.QuizMain.MD.txt`,
    `MedicalDevice.QuizMain.MDC.txt`,
    'MedicalDevice.Home.Description.txt',
    'MedicalDevice.Home.Title1.txt',
    'MedicalDevice.Home.Title2.txt',
]

const useStyle = makeStyles(() => ({
    description: {
        margin: 'auto',
        maxWidth: '720px',
        padding: '40px 20px',
    },
}));

const ga_ChooseMD = () => {
    ReactGA.event({
      category: 'Button',
      action: 'ChooseMD',
      label: 'Home/QuizMain/UserChooseMDQuiz',
    });
}

const ga_ChooseMDC = () => {
    ReactGA.event({
      category: 'Button',
      action: 'ChooseMDC',
      label: 'Home/QuizMain/UserChooseMDCQuiz',
    });
}