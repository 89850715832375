import React, { useEffect } from 'react';
import './Layout.css';
import { Outlet, useLocation } from 'react-router';
import { EcoSpinner, useQuizLoadTranslation, useTranslations } from '@eco/react-lib';
import { Link, useNavigate } from 'react-router-dom';

const Layout: React.FC = () => {
    const isLoaded = useQuizLoadTranslation(navigator.language.split('-')[0] ? navigator.language.split('-')[0] : navigator.language)
    const [CONTACT] = useTranslations(i18n);
    const navigate = useNavigate()
    const location = useLocation()
    const setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return ev.returnValue = 'You have attempted to leave this page. Are you sure?'
        });
    };

    useEffect(() => {
        setupBeforeUnloadListener()
    }, [])

    const handleHomeClick = () => {
        if(location.pathname !== '/quiz'){
            if (window.confirm("You have attempted to leave this page. Are you sure?")){
                navigate('/quiz')
            }
        }
    }

    return (
        <>
            <div className="mdheader">
                <hr className="mdtop-header"/>
                <div className="mdContainer">
                    <div className="mdlogo">
                        <span className="mdlogo-l"><a href="https://www.ecomundo.eu/" rel="noreferrer" target="_blank"><img src="/assets/img/logo-ecomundo-inv.png" /></a></span>
                        <span className="mdlogo-c" onClick={handleHomeClick} ><img src="/assets/img/MD-logo.png" /></span>
                        <Link to="/contact-us">{CONTACT}</Link>
                    </div>
                </div>
            </div>

            <div className="mdContainer">
                {
                    isLoaded ? <Outlet /> : <div className="quizSpinner"><EcoSpinner style={{ fontSize: 128 }} /></div>
                }
            </div>
        </>
    )
}

export default Layout;

const i18n = [
    '1Click.ContactForm.title'
]